<template>
  <nav
    id="nav"
    class="navbar navbar-light bx-navbar container d-flex flex-row pt-4"
  >
    <router-link
      class="navbar-brand pt-0 d-flex align-items-center text-light"
      to="/"
    >
      <img
        src="../assets/img/fps-logo.png"
        alt="Forskningspolitik.se"
        height="50"
      />
    </router-link>
    <ul
      class="navbar-nav ml-auto text-center d-flex flex-row align-items-center"
    >
      <li class="nav-item d-none d-sm-inline-block">
        <a
          href="https://forskningspolitik.blogspot.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-blogger bx-text-xxl ml-3" />
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a
          href="https://twitter.com/forskpolitik"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-twitter-square bx-text-xxl ml-3" />
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a
          href="https://sv-se.facebook.com/u.t.sandstrom"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-facebook-square bx-text-xxl ml-3" />
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a
          href="https://www.researchgate.net/profile/Ulf-Sandstroem-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fab fa-researchgate bx-text-xxl ml-3" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>
