<template>
  <div>
    <div>
      <Navbar dark />
    </div>
    <main>
      <div class="container-sm py-5">
        <div class="d-flex flex-column align-items-center">
          <h2 class="bx-title mt-2 mb-4">Forskningspolitik.se</h2>
          <div class="row mt-4">
            <div class="col-md-6 bx-text-lg text-justify mt-2">
              <div class="bx-text-700 text-justify mb-2">
                Welcome to forskningspolitik.se
              </div>
              <p>Forskningspolitik.se is Ulf Sandström's online site.</p>
              <p>
                Here are links to sites that contain my books and articles.
                First of these is
                <a
                  href="https://www.researchgate.net/profile/Ulf-Sandstroem-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Research Gate
                </a>
                which lists all my publications that are currently available in
                one form or another.
              </p>
              <p>
                Here you can also take part of my
                <a
                  href="https://forskningspolitik.blogspot.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog,
                </a>
                <a
                  href="https://twitter.com/forskpolitik"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter posts
                </a>
                and follow my
                <a
                  href="https://sv-se.facebook.com/u.t.sandstrom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook.
                </a>
              </p>
              <p>
                Ulf Sandström is the owner of the consulting company
                Forskningspolitik Sverige AB. The company conducts surveys on
                behalf of all types of customers, from the European Commission
                to small university colleges.
              </p>
            </div>
            <div class="col-md-6 bx-text-lg text-justify mt-2">
              <div class="bx-text-700 text-justify mb-2">
                Välkommen till forskningspolitik.se
              </div>
              <p>Forskningspolitik.se är Ulf Sandströms sida på nätet.</p>
              <p>
                Här finns länkar till sajter som innehåller mina böcker och
                artiklar. Främst av dessa är
                <a
                  href="https://www.researchgate.net/profile/Ulf-Sandstroem-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Research Gate
                </a>
                som förtecknar alla mina publikationer som för tillfället är
                tillgängliga i en eller annan form.
              </p>
              <p>
                Här kan du också ta del av min
                <a
                  href="https://forskningspolitik.blogspot.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  blog,
                </a>
                <a
                  href="https://twitter.com/forskpolitik"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  twitterinlägg
                </a>
                och följa min
                <a
                  href="https://sv-se.facebook.com/u.t.sandstrom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook-sida.
                </a>
              </p>
              <p>
                Ulf Sandström är ägare av konsultföretaget Forskningspolitik
                Sverige AB. Företaget gör undersökningar och tar uppdrag samt
                ger föreläsningar. Till kundkretsen hör allt från
                EU-kommissionen till mindre högskolor.
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-3">
              <a
                href="https://forskningspolitik.blogspot.com/"
                target="_blank"
                rel="noopener noreferrer"
                class="
                  bx-no-hover-decoration
                  d-flex
                  align-items-center
                  mt-2
                  mx-md-3
                "
              >
                <i class="fab fa-blogger bx-text-xxl mr-2" />
                Blog
              </a>
            </div>
            <div class="col-md-3">
              <a
                href="https://twitter.com/forskpolitik"
                target="_blank"
                rel="noopener noreferrer"
                class="
                  bx-no-hover-decoration
                  d-flex
                  align-items-center
                  mt-2
                  mx-md-3
                "
              >
                <i class="fab fa-twitter-square bx-text-xxl mr-2" />
                Twitter
              </a>
            </div>
            <div class="col-md-3">
              <a
                href="https://sv-se.facebook.com/u.t.sandstrom"
                target="_blank"
                rel="noopener noreferrer"
                class="
                  bx-no-hover-decoration
                  d-flex
                  align-items-center
                  mt-2
                  mx-md-3
                "
              >
                <i class="fab fa-facebook-square bx-text-xxl mr-2" />
                Facebook
              </a>
            </div>
            <div class="col-md-3">
              <a
                href="https://www.researchgate.net/profile/Ulf-Sandstroem-2"
                target="_blank"
                rel="noopener noreferrer"
                class="
                  bx-no-hover-decoration
                  d-flex
                  align-items-center
                  mt-2
                  mx-md-3
                "
              >
                <i class="fab fa-researchgate bx-text-xxl mr-2" />
                Research&nbsp;Gate
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Home",
  components: {
    Navbar,
  },
};
</script>
